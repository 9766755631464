<template>
  <div class="Announcement">
    <div class="AnnouncementButton">
      <v-btn tile text icon small @click="moveText('left')">
        <v-icon color="black">mdi-chevron-left</v-icon>
      </v-btn>
    </div>

    <div class="AnnouncementBody">
      <span>
        {{ announcementText }}
      </span>
    </div>

    <div class="AnnouncementButton">
      <v-btn tile text icon small @click="moveText('right')">
        <v-icon color="black"> mdi-chevron-right </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      announcementText: "",
      textList: [
        "2024.09.24 - 오더페이지 컬러 Surcharge 등록 시 반영되도록 변경",
        "2024.09.24 - 메인 페이지 통계 구분 추가",
        "2024.09.24 - B/T, MAIN, 이화학 이미지 붙여넣기 기능 추가",
        "2024.09.24 - 클레임 저장로직 개선",
        "2024.09.24 - 요청 기능 변경",
      ],
      textIndex: 0,
      isTimer: true,
    };
  },
  methods: {
    setAnnouncement() {
      const timer = setInterval(() => {
        if (this.isTimer) this.setText();
        else clearInterval(timer);
      }, 3000);
    },
    setText() {
      const textLength = this.textList.length;
      const index = this.textIndex + 1;
      if (index < textLength && index !== textLength) {
        this.announcementText = this.textList[index];
        this.textIndex = index;
      } else {
        this.announcementText = this.textList[0];
        this.textIndex = 0;
      }
    },
    moveText(kind) {
      this.isTimer = false;
      const textLength = this.textList.length;
      const index = this.textIndex + (kind === "right" ? +1 : -1);

      this.textIndex =
        index < 0
          ? textLength - 1
          : textLength < index || textLength === index
          ? 0
          : index;

      this.announcementText = this.textList[this.textIndex];
    },
    startAnnouncement() {
      this.isTimer = true;
      this.setAnnouncement();
    },
  },
  created() {
    this.announcementText = this.textList[0];
    this.setAnnouncement();
  },
};
</script>
